/**
 * Track which form checkboxes have changed in the json form field "user_changed_field"
 *
 * Allows us to know when a checkbox has been unticked and re-ticked, or vice versa
 */

document.addEventListener('DOMContentLoaded', () => {
    const logFields = document.querySelectorAll(
        'input[name="user_changed_field"]'
    );
    logFields.forEach((logField) => {
        const fields = logField
            .closest('form')
            .querySelectorAll('input[type="checkbox"]');
        const logData = {};
        fields.forEach((field) => {
            field.addEventListener('change', (e) => {
                logData[e.target.name] = true;
                logField.value = JSON.stringify(logData);
            });
        });
    });
});
